function CurrentProgressStep({
  index,
  step,
  currentStep,
}: {
  index: number;
  step: string;
  currentStep: number;
}) {
  return (
    <div
      key={index}
      className={`relative flex items-center justify-center text-center transition-all duration-500 ease-in-out ${
        index < currentStep
          ? "text-green-500"
          : index === currentStep
          ? "bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-transparent bg-clip-text text-transparent-custom animate-gradient"
          : "text-gray-500"
      }`}
      style={{
        fontSize: index === currentStep ? "2em" : "1.5em",
        position: "relative",
        marginTop: index === 0 ? 0 : "3.2rem",
        opacity: 1,
        transform: index <= currentStep ? "translateY(0)" : "translateY(-10px)",
        animation: `${
          index === currentStep
            ? "gradient 6s infinite, fadeIn 0.5s ease-in"
            : "fadeIn 0.5s ease-in"
        }`,
      }}
    >
      {index < currentStep && (
        <span className="text-green-500 mr-2 text-xl">✔️</span>
      )}
      {step}
      {index === currentStep && <div className="ml-2 circle-spinner"></div>}
    </div>
  );
}

export default CurrentProgressStep;
