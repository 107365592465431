import MainLayout from "../../layouts/main";

import InviteUser from "../../containers/profile/users/invite";

function Invite() {
  return (
    <MainLayout showSidebarLogo showSidebar>
      <InviteUser />
    </MainLayout>
  );
}

export default Invite;
