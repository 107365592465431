import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../../axios";
import { queryClient } from "../../../App";

export interface ICreateBannerBearImagesRequest {
  websiteId: number;
  advertiserId: number;
  campaignId: number;
}

export interface IBannerBearAdObject {
  id: number;
  bannerbearImageUid: string;
  websiteId: number;
  bannerbearTemplateId: string;
  imageUrl: string | null;
  createdAt: Date;
  updatedAt: Date;
  templateType: string;
}
export interface IBannerBearImagesResponse {
  displayAds: IBannerBearAdObject[];
  socialAds: IBannerBearAdObject[];
  searchAds: IBannerBearAdObject[];
}

/*Producing bannerbear images */
const createBannerBearImages = (data: ICreateBannerBearImagesRequest) => {
  return request({
    url: "bannerbear-images",
    method: "post",
    data: data,
  });
};

export const useCreateBannerBearImages = (campaignId: number | undefined) => {
  return useMutation({
    mutationKey: ["createBannerBearImages", campaignId],
    mutationFn: createBannerBearImages,
    onSuccess(result) {
      queryClient.invalidateQueries({
        queryKey: ["bannerbearImages", campaignId],
      });
    },
  });
};

/*getting bannerbear images */
const getBannerBearImages = (
  campaignId: number | undefined
): Promise<IBannerBearImagesResponse> => {
  return request({
    url: `bannerbear-images/campaign/${campaignId}`,
    method: "get",
  });
};

export const useBannerBearImages = (campaignId: number | undefined) => {
  return useQuery({
    queryKey: ["bannerbearImages", campaignId],
    queryFn: () => getBannerBearImages(campaignId),
    enabled: !!campaignId,
  });
};
