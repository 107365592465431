export interface ISidebarLink {
  id: string;
  icon: (isActive: boolean) => JSX.Element;
  text: string;
  link: string;
  subNav?: null | any;
}

export const sidebarLinks: ISidebarLink[] = [
  {
    id: "sn-1",
    icon: (isActive: boolean) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className={`flex-shrink-0 fill-current w-5 h-5 ${
          isActive ? "text-gray-900" : "text-gray-500"
        }  transition duration-75`}
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        // fill="#e8eaed"
      >
        <path d="M468-240q-96-5-162-74t-66-166q0-100 70-170t170-70q97 0 166 66t74 162l-84-25q-13-54-56-88.5T480-640q-66 0-113 47t-47 113q0 57 34.5 100t88.5 56l25 84Zm48 158q-9 2-18 2h-18q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v18q0 9-2 18l-78-24v-12q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h12l24 78Zm305 22L650-231 600-80 480-480l400 120-151 50 171 171-79 79Z" />
      </svg>
    ),
    text: "Campaigns",
    link: "/campaigns",
    subNav: null,
  },
  {
    id: "sn-2",
    icon: (isActive: boolean) => (
      <svg
        className={`flex-shrink-0 fill-current w-5 h-5 ${
          isActive ? "text-gray-900" : "text-gray-500"
        }  transition duration-75`}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
      >
        <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-82q26-36 45-75t31-83H404q12 44 31 83t45 75Zm-104-16q-18-33-31.5-68.5T322-320H204q29 50 72.5 87t99.5 55Zm208 0q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584-178ZM170-400h136q-3-20-4.5-39.5T300-480q0-21 1.5-40.5T306-560H170q-5 20-7.5 39.5T160-480q0 21 2.5 40.5T170-400Zm216 0h188q3-20 4.5-39.5T580-480q0-21-1.5-40.5T574-560H386q-3 20-4.5 39.5T380-480q0 21 1.5 40.5T386-400Zm268 0h136q5-20 7.5-39.5T800-480q0-21-2.5-40.5T790-560H654q3 20 4.5 39.5T660-480q0 21-1.5 40.5T654-400Zm-16-240h118q-29-50-72.5-87T584-782q18 33 31.5 68.5T638-640Zm-234 0h152q-12-44-31-83t-45-75q-26 36-45 75t-31 83Zm-200 0h118q9-38 22.5-73.5T376-782q-56 18-99.5 55T204-640Z" />
      </svg>
    ),
    text: "Websites",
    link: "/websites",
    subNav: null,
  },
  // {
  //   id: "sn-3",
  //   icon: (isActive: boolean) => (
  //     <svg
  //       className={`flex-shrink-0 fill-current w-5 h-5 ${
  //         isActive ? "text-gray-900" : "text-gray-500"
  //       }  transition duration-75`}
  //       xmlns="http://www.w3.org/2000/svg"
  //       height="24px"
  //       viewBox="0 -960 960 960"
  //       width="24px"
  //     >
  //       <path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" />
  //     </svg>
  //   ),
  //   text: "Notifications",
  //   link: "/notifications",
  //   subNav: null,
  // },
  {
    id: "sn-4",
    icon: (isActive: boolean) => (
      <svg
        className={`flex-shrink-0 fill-current w-5 h-5 ${
          isActive ? "text-gray-900" : "text-gray-500"
        }  transition duration-75`}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
      >
        <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z" />
      </svg>
    ),
    text: "User Management",
    link: "/profile",
    subNav: null,
  },
  {
    id: "sn-5",
    icon: (isActive: boolean) => (
      <svg
        className={`flex-shrink-0 fill-current w-5 h-5 ${
          isActive ? "text-gray-900" : "text-gray-500"
        }  transition duration-75`}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        // fill="#e8eaed"
      >
        <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-80q-100 0-170-70t-70-170q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70Zm0-80q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-80q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Z" />
      </svg>
    ),
    text: "Advertisers",
    link: "/advertiser",
    subNav: null,
  },
  // {
  //   id: "sn-6",
  //   icon: (isActive: boolean) => (
  //     <svg
  //       className={`flex-shrink-0 fill-current w-5 h-5 ${
  //         isActive ? "text-gray-900" : "text-gray-500"
  //       }  transition duration-75`}
  //       xmlns="http://www.w3.org/2000/svg"
  //       height="24px"
  //       viewBox="0 -960 960 960"
  //       width="24px"
  //       // fill="#e8eaed"
  //     >
  //       <path d="M120-120v-80l80-80v160h-80Zm160 0v-240l80-80v320h-80Zm160 0v-320l80 81v239h-80Zm160 0v-239l80-80v319h-80Zm160 0v-400l80-80v480h-80ZM120-327v-113l280-280 160 160 280-280v113L560-447 400-607 120-327Z" />
  //     </svg>
  //   ),
  //   text: "Insights",
  //   link: "/insights",
  //   subNav: null,
  // },
  // {
  //   id: "sn-7",
  //   icon: (isActive: boolean) => (
  //     <svg
  //       className={`flex-shrink-0 fill-current w-5 h-5 text-gray-500 transition duration-75`}
  //       xmlns="http://www.w3.org/2000/svg"
  //       height="24px"
  //       viewBox="0 -960 960 960"
  //       width="24px"
  //       // fill="#e8eaed"
  //     >
  //       <path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
  //     </svg>
  //   ),
  //   text: "Help",
  //   link: "https://veloxiai.freshdesk.com/support/home",
  //   subNav: null,
  // },
];
