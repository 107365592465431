import { useParams } from "react-router-dom";

import ContentContainer from "../../components/contentContainer";
import TopContent from "../../components/topContent";
import ActivitySpinnerNoBg from "../../components/activitySpinner/withNoBgEffect";

import { useAdvertiserBusinessProfile } from "../../services/google-business-profile";

import { parseStringObject } from "../../utils/parseStringObject";

function AdvertiserGoogleBusinessAcc() {
  const { id } = useParams<{ id: string }>();

  const idToNumber = id ? parseInt(id, 10) : null;

  const { data, isPending } = useAdvertiserBusinessProfile(idToNumber);

  const businessProfileData = data && data[0];
  return (
    <div>
      <TopContent
        title="Advertiser Profile"
        subTitle="Advertiser information from the web"
      />
      {isPending ? (
        <ActivitySpinnerNoBg />
      ) : (
        <ContentContainer className="p-7">
          {businessProfileData ? (
            <>
              <h3 className="text-primary mb-2 text-sm font-interSemiBold">
                Business Summary
              </h3>
              <div className="flex flex-col space-y-1">
                <p className="text-sm text-primary font-interSemiBold">
                  Profile Address:
                  <span className="ml-1.5 font-interRegular">
                    {businessProfileData.address}
                  </span>
                </p>
                <p className="text-sm text-primary font-interSemiBold">
                  Profile Phone:
                  <span className="ml-1.5 font-interRegular">
                    {businessProfileData.phone}
                  </span>
                </p>
                <p className="text-sm text-primary font-interSemiBold">
                  Profile Company Description:
                  <span className="ml-1.5 font-interRegular">
                    {businessProfileData.description}
                  </span>
                </p>
              </div>

              <h3 className="text-primary mt-8 mb-2 text-sm font-interSemiBold">
                Social Profiles
              </h3>
              <div className="flex flex-col space-y-1">
                <p className="text-sm text-primary font-interSemiBold">
                  Instagram:
                  <span className="ml-1.5 font-interRegular">
                    {businessProfileData.instagram}
                  </span>
                </p>
                <p className="text-sm text-primary font-interSemiBold">
                  Facebook:
                  <span className="ml-1.5 font-interRegular">
                    {businessProfileData.facebook}
                  </span>
                </p>
                <p className="text-sm text-primary font-interSemiBold">
                  Twitter:
                  <span className="ml-1.5 font-interRegular">
                    {businessProfileData.twitter}
                  </span>
                </p>
                <p className="text-sm text-primary font-interSemiBold">
                  Tiktok:
                  <span className="ml-1.5 font-interRegular">
                    {businessProfileData.tiktok}
                  </span>
                </p>
                <p className="text-sm text-primary font-interSemiBold">
                  Linkedin:
                  <span className="ml-1.5 font-interRegular">
                    {businessProfileData.linkedin}
                  </span>
                </p>
              </div>

              <h3 className="text-primary mt-8 mb-2 text-sm font-interSemiBold">
                Reviews
              </h3>
              <div className="flex flex-col space-y-1">
                <p className="text-sm text-primary font-interSemiBold">
                  Profile Review:
                  <span className="ml-1.5 font-interRegular">
                    {parseStringObject(businessProfileData.review)}
                  </span>
                </p>
                <p className="text-sm text-primary font-interSemiBold">
                  Profile Reviews From The Web:
                  <span className="ml-1.5 font-interRegular">
                    {parseStringObject(businessProfileData.reviewsFromWeb)}
                  </span>
                </p>
              </div>
            </>
          ) : (
            <p className="text-sm text-primary font-interSemiBold">
              Updating...
            </p>
          )}
        </ContentContainer>
      )}
    </div>
  );
}

export default AdvertiserGoogleBusinessAcc;
