import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { queryClient } from "../../App";

import firebase from "../../firebase";

import { clearAxiosAuthHeader, setAxiosAuthHeader } from "../../services/axios";
import { useCreateSelf } from "../../services/user";

import { useSelectedAdvertiser } from "../selectedAdvertiser";

import { IEmailResetRequest, SignUpFormData } from "../../interfaces";

export interface AuthContextInterface {
  user: any;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
  clearAuthStates: () => void;
  setFirebaseTokenAsAxiosAuthToken: (forceRefresh: boolean) => void;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (form: SignUpFormData) => Promise<any | null | undefined>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateUserPassword: (
    email: string,
    currentPassword: string,
    newPassword: string
  ) => Promise<void>;
  setIsEmailResetRequest: React.Dispatch<
    React.SetStateAction<IEmailResetRequest>
  >;
  isEmailResetRequest: IEmailResetRequest;
  tokenRefreshed: boolean;
  sessionToken?: string;
}

const AuthContext = createContext({} as AuthContextInterface);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const useAuthProvider = () => {
  const { mutate: createNewUser, isSuccess: isUserCreateSuccess } =
    useCreateSelf();
  const { setSelectedAdvertiser } = useSelectedAdvertiser();

  const [user, setUser] = useState<any>(localStorage.getItem("user") || null);
  const [sessionToken, setSessionToken] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [tokenRefreshed, setTokenRefreshed] = useState<boolean>(false);

  const [isEmailResetRequest, setIsEmailResetRequest] =
    useState<IEmailResetRequest>({
      isLoading: false,
      isSuccess: false,
      isError: false,
    });

  async function onAuthStateChanged(user: any) {
    if (user) {
      setUser(user);
      localStorage.setItem("user", user);
      await setFirebaseTokenAsAxiosAuthToken(false);
    } else {
      setUser(null);
      await queryClient.refetchQueries({ queryKey: ["domainStyle"] });
      queryClient
        .getQueryCache()
        .getAll()
        .forEach((query) => {
          if (
            JSON.stringify(query.queryKey) !== JSON.stringify(["domainStyle"])
          ) {
            queryClient.removeQueries({ queryKey: query.queryKey });
          }
        });
      localStorage.clear();
      clearAxiosAuthHeader();
    }
  }

  function beforeRequest() {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);
    setErrorMessage(null);
  }

  function successfulRequest() {
    setIsLoading(false);
    setIsSuccess(true);
    setIsError(false);
    setErrorMessage(null);
  }

  function erroneousRequest(errorMessage?: string) {
    setIsLoading(false);
    setIsSuccess(false);
    setIsError(true);
    setErrorMessage(errorMessage ?? "Something went wrong");
  }

  function clearAuthStates() {
    setIsLoading(false);
    setIsSuccess(false);
    setIsError(false);
    setErrorMessage(null);
  }

  async function setFirebaseTokenAsAxiosAuthToken(reloadUser: boolean) {
    try {
      const token = await firebase.auth().currentUser?.getIdToken(reloadUser);
      reloadUser && (await firebase.auth().currentUser?.reload());
      if (!token) return;
      // console.log("Auth Token - ", token);
      setAxiosAuthHeader(token);
      setSessionToken(token);
    } catch (error: any) {
      // console.log(`Auth token could not refreshed`);
    }
  }

  async function signIn(
    email: string,
    password: string,
    successCallback?: () => any
  ) {
    try {
      beforeRequest();
      await firebase.auth().signInWithEmailAndPassword(email, password);
      successfulRequest();
      successCallback && successCallback();
      // console.log("User signed in successfully");
    } catch (error: any) {
      const customErrorMessage = mapFirebaseErrCodes(
        error?.code,
        error?.message
      );
      erroneousRequest(customErrorMessage);
    }
  }

  function mapFirebaseErrCodes(errCode: string, defaultErrMsg: string) {
    switch (errCode) {
      case "auth/email-already-in-use":
        return "The email address is already in use by another account.";
      case "auth/email-already-exists":
        return "The email address is already in use by another account.";
      case "auth/invalid-display-name":
        return "Invalid display name value";
      case "auth/user-not-found":
        return "There is no existing user record corresponding to the credential";
      case "auth/too-many-requests":
        return "Too many unsuccessful attempts. Make sure you log into an existing account!";
      case "auth/invalid-email":
        return "The email address is invalid";
      case "auth/invalid-credential":
        return "The information you entered doesn't match our records. Please try again or click Forgot Password";
      case "auth/invalid-email-verified":
        return "The provided value for the emailVerified user property is invalid";
      default:
        return defaultErrMsg;
    }
  }

  async function signUp(userForm: SignUpFormData, successCallback?: () => any) {
    try {
      beforeRequest();
      const { email, password, ...rest } = userForm;
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      await user?.sendEmailVerification();
      createNewUser({ email, ...rest });
      successfulRequest();
      successCallback && successCallback();
    } catch (error: any) {
      const customErrorMessage = mapFirebaseErrCodes(
        error?.code,
        error?.message
      );
      erroneousRequest(customErrorMessage);
    }
  }

  async function resetPassword(email: string) {
    try {
      beforeRequest();
      firebase.auth().sendPasswordResetEmail(email);
      successfulRequest();
    } catch (error: any) {
      erroneousRequest(error?.message);
    }
  }

  async function updateUserPassword(
    email: string,
    currentPassword: string,
    newPassword: string
  ) {
    try {
      beforeRequest();
      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        currentPassword
      );
      await firebase
        .auth()
        .currentUser?.reauthenticateWithCredential(credential);
      await user?.updatePassword(newPassword);
      successfulRequest();
    } catch (error: any) {
      erroneousRequest(error?.message);
    }
  }

  async function signOut(successCallback?: () => any) {
    beforeRequest();
    try {
      await queryClient.refetchQueries({ queryKey: ["domainStyle"] });
      queryClient
        .getQueryCache()
        .getAll()
        .forEach((query) => {
          if (
            JSON.stringify(query.queryKey) !== JSON.stringify(["domainStyle"])
          ) {
            queryClient.removeQueries({ queryKey: query.queryKey });
          }
        });
      setTokenRefreshed(false);
      await firebase.auth().signOut();
      setSessionToken(undefined);
      setSelectedAdvertiser(null);
      successfulRequest();
      successCallback && successCallback();
    } catch (error: any) {
      erroneousRequest(error?.message);
    }
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isUserCreateSuccess) {
      setFirebaseTokenAsAxiosAuthToken(true);
      setTokenRefreshed(true);
    } else {
      setTokenRefreshed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserCreateSuccess]);

  return {
    user,
    isLoading,
    isSuccess,
    isError,
    errorMessage,
    isEmailResetRequest,
    setIsEmailResetRequest,
    clearAuthStates,
    setFirebaseTokenAsAxiosAuthToken,
    signUp,
    signIn,
    resetPassword,
    updateUserPassword,
    signOut,
    tokenRefreshed,
    sessionToken,
  };
};
