import "./App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { APIProvider } from "@vis.gl/react-google-maps";

import { AuthProvider } from "./contexts/auth";
import { SelectedAdvertiserProvider } from "./contexts/selectedAdvertiser";
import { ToggleSidebarProvider } from "./contexts/toggleSidebar";
import { DomainProvider } from "./contexts/subDomainData";
import { StepProvider } from "./contexts/stepper";
import { CampaignProvider } from "./contexts/campaign";
import { ChatBotProvider } from "./contexts/chatbot";

import { RootNavigator } from "./navigation";

export const queryClient = new QueryClient({});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StepProvider>
        <SelectedAdvertiserProvider>
          <CampaignProvider>
            <AuthProvider>
              <DomainProvider>
                <ChatBotProvider>
                  <ToggleSidebarProvider>
                    <APIProvider
                      apiKey={"AIzaSyDKVLTjkjPNb1la_wtdbnlBc-yFhRI6BLk"}
                      // onLoad={() => console.log("Maps API has loaded.")}
                    >
                      <RootNavigator />
                    </APIProvider>
                  </ToggleSidebarProvider>
                </ChatBotProvider>
              </DomainProvider>
            </AuthProvider>
          </CampaignProvider>
        </SelectedAdvertiserProvider>
      </StepProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
