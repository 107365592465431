import React, { useEffect, useRef, useState } from "react";
import UsersMessage from "./usersMessage";
import BotMessage from "./botMessage";
import { useChatBot } from "../../contexts/chatbot";

const API = {
  GetChatbotResponse: async (message: string): Promise<string> => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        if (message === "hi") resolve("Welcome to chatbot!");
        else resolve("response : " + message);
      }, 2000);
    });
  },
};

const Chatbot = () => {
  const el = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<React.ReactNode[]>([]);
  const [text, setText] = useState("");

  const { isToggled, handleToggle } = useChatBot();

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setText(e.target.value);
  }

  async function send(text: string) {
    const newMessages = messages.concat(
      <UsersMessage key={messages.length + 1} text={text} />,
      <BotMessage
        key={messages.length + 2}
        fetchMessage={async () => await API.GetChatbotResponse(text)}
      />
    );
    setMessages(newMessages);
  }

  function handleSend(e: React.FormEvent) {
    e.preventDefault();
    send(text);
    setText("");
  }

  useEffect(() => {
    if (el.current) {
      el.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  });

  useEffect(() => {
    const initialMessage =
      "Hi, I’m your marketing sidekick.Whether you’re here to brainstorm ideas,refine your ad strategy,support for the application,or just curious about what’s possible,I’ve got your back.Just type away—I’m here to help you level up your marketing game.Let’s get started!";
    setMessages([
      <BotMessage key={0} fetchMessage={async () => initialMessage} />,
    ]);
  }, []);

  return (
    <>
      {/* <div
        onClick={handleToggle}
        className={`${
          isToggled
            ? "fixed right-4 cursor-pointer z-[100] bottom-10 w-12 h-12 rounded-full bg-blue-900 flex items-center justify-center shadow-xl "
            : "hidden"
        } `}
      >
        <p className="text-white text-xl font-interSemiBold">?</p>
      </div> */}
      <div
        className={`fixed ${
          isToggled ? "h-0" : "h-[26.2rem]"
        } z-[100] right-2 bottom-0`}
      >
        <div className="flex flex-col overflow-hidden w-[25rem] text-sm font-interRegular rounded-[.625rem] shadow-md bg-[#f5f8fb] text-center">
          <div className="p-2.5 cursor-pointer relative text-center text-white text-base font-interBold bg-[#002f50]">
            <p>&nbsp;Your Marketing Assistant</p>
            <div
              onClick={handleToggle}
              className="absolute top-2 right-3 cursor-pointer text-xl rounded-full "
            >
              {!isToggled && "X"}
            </div>
          </div>
          <div className="flex flex-col overflow-hidden transition-all duration-300">
            <div className="h-[20rem] flex flex-col overflow-auto py-2.5 px-0 w-full">
              {messages}
              <div id={"el"} ref={el} />
            </div>
            <div className="chatbot-input">
              <form onSubmit={handleSend}>
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={text}
                  placeholder="Enter your message here"
                />
                <button>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 500 500"
                  >
                    <g>
                      <g>
                        <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
                      </g>
                    </g>
                  </svg>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chatbot;
