import { useNavigate } from "react-router-dom";

import { ColDef } from "ag-grid-community";

import ContentContainer from "../../components/contentContainer";
import RoundedButton from "../../components/roundedButton";
import { Selection } from "../../components/select";
import Table from "../../components/table";

import { IAllCampaignsDataResponse } from "../../services/campaigns";

import { TPaginatedDataResponse } from "../../interfaces";

function CampaignListView({
  tableTotalPages,
  setDataPageNumber,
  setFilterStatus,
  onDropdownChange,
  gridApi,
  numberOfItemsInEachPage,
  onGridReady,
  columnData,
  campaignsList,
}: {
  tableTotalPages: number;
  setDataPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setFilterStatus: React.Dispatch<React.SetStateAction<string | null>>;
  onDropdownChange(value: string | null): void;
  gridApi: any;
  numberOfItemsInEachPage: number;
  onGridReady: (params: any) => void;
  columnData: ColDef<any, any>[];
  campaignsList: TPaginatedDataResponse<IAllCampaignsDataResponse> | undefined;
}) {
  const navigate = useNavigate();

  return (
    <ContentContainer>
      <div className="flex  my-[1px] items-center ">
        <div className="py-2 flex items-center ">
          <p className="mx-5 font-interSemiBold text-sm text-primary">
            Filter by:{" "}
          </p>
          <Selection
            data={[
              {
                label: "all",
                value: null,
              },
              {
                label: "draft",
                value: "draft",
              },
              {
                label: "active",
                value: "active",
              },
            ]}
            isMulti={undefined}
            closeMenuOnSelect={true}
            placeholder={"Status"}
            customStyle={{
              selectContainer: {
                marginBlock: "-2px",
                paddingInline: 7,
                fontSize: 14,
                fontFamily: "InterRegular",
                paddingBlock: 0,
                borderRadius: "7px",
                width: "15rem",
                marginRight: ".7rem",
              },
              color: "#d3d3d3",
              optionisSelectedBgColor: "transparent",
              optionisFocusedBgColor: "#d7edff",
              optionisFocusedTextColor: "#0e73f6",
              singleValStyles: {
                backgroundColor: "transparent",
              },
              valueContainer: {
                fontFamily: "InterRegular",
              },
            }}
            onChange={(selectedOption: any, actionMeta: any) => {
              const value = selectedOption ? selectedOption.value : null;
              setFilterStatus(value);
              onDropdownChange(value);
            }}
          />
        </div>
        <div className="max-w-fit flex items-center  ml-auto">
          {/* {assetErrorMessage && (
    <p className="text-red-400 font-ManropeSemiBold  text-sm mr-5">
      {assetErrorMessage}
    </p>
  )} */}
          <RoundedButton
            onClick={() => navigate("/campaigns/wizard")}
            text={
              <p className=" text-sm text-white inline-flex items-center font-interRegular">
                <span className="text-2xl  mr-2 text-white">+</span>
                Add Campaign
              </p>
            }
            bgColor="bg-[#3077aa]"
            borderRadius="rounded-lg"
            textColor="text-secondary"
            className=" ml-auto px-6 py-1 mr-1 text-sm font-interSemiBold"
          />
        </div>
      </div>

      <Table
        setDataPageNumber={setDataPageNumber}
        gridApi={gridApi}
        tableTotalPages={tableTotalPages}
        tableId="campaignGrid"
        tableStyle={{
          minHeight: "200px",
          maxHeight: "1149px",
        }}
        rowHeight={80}
        paginationPageSize={numberOfItemsInEachPage}
        onGridReady={onGridReady}
        columnData={columnData}
        assetRowData={campaignsList?.data ?? []}
      />
    </ContentContainer>
  );
}

export default CampaignListView;
