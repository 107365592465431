import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useForm, SubmitHandler } from "react-hook-form";

import { useAuth } from "../../contexts/auth";

import Button from "../../components/button";
import Input from "../../components/input/floatinLabelInput";
import RoundedButton from "../../components/roundedButton";

// import SparkLogo from "../../assets/icons/spark-logo-black-text.png";

const ForgetPassword = () => {
  const [focusedField, setFocusedField] = useState<string | null>(null);

  const {
    resetPassword,
    isLoading: isEmailRequestLoading,
    isSuccess: isEmailRequestSuccess,
  } = useAuth();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ email: string }>();

  const formData = watch();

  const onSubmit: SubmitHandler<{ email: string }> = async (data) => {
    resetPassword(data.email);
  };

  if (isEmailRequestSuccess)
    return (
      <div className="absolute  top-1/2  left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <p className="text-[#0e086a] font-ManropeSemiBold text-2xl">
          Your password reset link was sent to your email account!
        </p>

        <Button
          onClick={() => {
            navigate("/signin");
          }}
          btnText="Go back to sign in"
          bgColor="bg-[#0e086a]"
          marginValues="mt-20 mx-auto"
          isScaledOnHover={true}
        />
      </div>
    );

  return (
    <div className="absolute border border-solid border-gray-200 rounded-lg py-12 px-8  top-1/2  left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      {/* <div className="w-full h-[4.5rem]  mb-6">
        <img
          src={SparkLogo}
          className="w-full h-full object-contain "
          alt="kolay-ads.png"
        />
      </div> */}
      <h3 className="mb-5 font-interSemiBold text-[#0e086a] text-2xl text-center ">
        Request Password Reset
      </h3>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-[25rem]  gap-x-3 gap-y-6"
      >
        <Input
          id="rp-email"
          name="email"
          type="email"
          label="Email"
          reactHooksFormEnabled={true}
          register={register}
          pattern={{
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Entered value does not match email format",
          }}
          requiredMessage="Email is required"
          value={formData["email"]}
          errorMessage={errors.email?.message}
          focusedField={focusedField}
          setFocusedField={setFocusedField}
        />

        <RoundedButton
          className="py-3 px-4 mt-5 hover:bg-blue-900"
          type="submit"
          text={(isEmailRequestLoading && "Processing...") || "Reset password"}
        />

        <RoundedButton
          className="py-3 px-4 bg-slate-500 hover:bg-slate-600"
          onClick={() => navigate("signin")}
          type="submit"
          text="Sign In"
        />
      </form>
    </div>
  );
};

export default ForgetPassword;
