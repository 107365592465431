import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { ColDef } from "ag-grid-community";

import ContentContainer from "../../../components/contentContainer";
import RoundedButton from "../../../components/roundedButton";
import Table from "../../../components/table";
import { Selection } from "../../../components/select";
import TopContent from "../../../components/topContent";

import { ReactComponent as EditSvg } from "../../../assets/icons/edit-icon-mui.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete-icon-mui.svg";

import { useAllUsers } from "../../../services/user";

function Users() {
  const navigation = useNavigate();

  const [tableTotalPages, setTableTotalPages] = useState<number>(0);
  const [, setDataPageNumber] = useState<number>(0);
  const { data: allUsersData } = useAllUsers();

  const numberOfItemsInEachPage = 10;

  const [gridApi, setGridApi] = useState<any>(null);
  const [filterStatus, setFilterStatus] = useState<string | undefined>(
    undefined
  );

  const columnData: ColDef[] = [
    // {
    //   headerName: "NAME",
    //   field: "displayName",
    //   flex: 1.5,
    //   sortable: false,
    //   suppressSpanHeaderHeight: true,
    //   cellStyle: { paddingLeft: "20px" },
    //   resizable: false,
    //   headerClass: "custom-table-header-padding",
    // },
    {
      headerName: "EMAIL",
      field: "email",
      flex: 3,
      headerClass: "ag-header-border",
      sortable: false,
      filter: true,
      resizable: false,
    },
    {
      headerName: "ACCESS LEVEL",
      field: "role",
      sortable: false,
      flex: 1.5,
      headerClass: "ag-header-border",
      filter: true,
      resizable: false,
    },
    {
      headerName: "ACTION",
      sortable: false,
      width: 120,
      resizable: false,
      cellRenderer: (params: any) => {
        return (
          <div className="flex">
            {/* <div onClick={() => navigation("/profile/user")}> */}
            <div
            // onClick={() => onClickEdit("editProfile")}
            >
              {<EditSvg className="w-7 h-6 text-gray-500 fill-current" />}
            </div>
            <div
              //   onClick={() => onClickEdit("changePassword")}
              className="ml-1.5"
            >
              {<DeleteIcon className="w-7 h-6 text-gray-500 fill-current" />}
            </div>
          </div>
        );
      },
    },
  ];

  function onDropdownChange(value: string) {
    if (gridApi) {
      console.info(value);
      if (value === "BASIC" || value === "ADVERTISER_ADMIN") {
        gridApi.setFilterModel({
          role: {
            filterType: "text",
            type: "equals",
            filter: value,
          },
        });
      } else {
        gridApi.setFilterModel(null);
      }
    }
  }

  useEffect(() => {
    if (gridApi && filterStatus === undefined) {
      // Clear the filter when no filter is selected or reset is triggered
      gridApi.setFilterModel(null);
    }
    //eslint-disable-next-line
  }, [filterStatus]);

  useEffect(() => {
    if (gridApi && allUsersData) {
      const totalPages = allUsersData.length / numberOfItemsInEachPage;
      setTableTotalPages(totalPages);
    }
    // eslint-disable-next-line
  }, [gridApi, allUsersData?.length]);

  return (
    <>
      <div className="flex flex-col">
        <TopContent
          title="User Management"
          subTitle="Manage and control access and administrative rights to the account"
        />
        <ContentContainer>
          <div>
            <div className="flex  my-[1px] items-center">
              <div className="py-2 flex items-center ">
                <p className="mx-5 font-interSemiBold text-sm text-primary">
                  Filter by:{" "}
                </p>
                <Selection
                  data={[
                    {
                      label: "all",
                      value: null,
                    },
                    {
                      label: "basic",
                      value: "BASIC",
                    },
                    {
                      label: "admin",
                      value: "ADVERTISER_ADMIN",
                    },
                  ]}
                  isMulti={undefined}
                  closeMenuOnSelect={true}
                  placeholder={"Access Level"}
                  customStyle={{
                    selectContainer: {
                      marginBlock: "-2px",
                      paddingInline: 7,
                      fontSize: 14,
                      fontFamily: "InterRegular",
                      paddingBlock: 0,
                      borderRadius: "7px",
                      width: "15rem",
                      marginRight: ".7rem",
                    },
                    color: "#d3d3d3",
                    optionisSelectedBgColor: "transparent",
                    optionisFocusedBgColor: "#335498",
                    optionisFocusedTextColor: "white",
                    singleValStyles: {
                      backgroundColor: "transparent",
                    },
                    valueContainer: {
                      fontFamily: "InterRegular",
                    },
                  }}
                  onChange={(selectedOption: any, actionMeta: any) => {
                    onDropdownChange(
                      selectedOption ? selectedOption.value : ""
                    );
                    setFilterStatus(
                      selectedOption ? selectedOption.value : undefined
                    );
                  }}
                />
              </div>

              <div className="max-w-fit flex items-center  ml-auto">
                <RoundedButton
                  text={
                    <p
                      onClick={() => navigation("/profile/users/invite")}
                      className=" text-sm text-white inline-flex items-center font-interRegular"
                    >
                      <span className="text-2xl   mr-2 text-white">+</span>
                      Add User
                    </p>
                  }
                  bgColor="bg-[#3077aa]"
                  borderRadius="rounded-lg"
                  textColor="text-secondary"
                  className=" ml-auto px-6 py-1 text-sm font-interSemiBold mr-1"
                />
              </div>
            </div>
            <Table
              gridApi={gridApi}
              tableId="usersGrid"
              tableTotalPages={tableTotalPages}
              // tableTotalPages={1}
              setDataPageNumber={setDataPageNumber}
              tableStyle={{
                minHeight: "200px",
                maxHeight: "1149px",
              }}
              rowHeight={50}
              paginationPageSize={numberOfItemsInEachPage}
              onGridReady={(params) => setGridApi(params.api)}
              columnData={columnData}
              assetRowData={allUsersData || []}
            />
          </div>
        </ContentContainer>
      </div>
    </>
  );
}

export default Users;
