function UsersMessage({ text }: { text: string }) {
  return (
    <div className="w-full">
      <div
        style={{ borderRadius: "20px 20px 1px 20px" }}
        className="float-right py-4 px-2.5 my-2.5 ml-5 mr-2 bg-[#cccccc] text-black"
      >
        {text}
      </div>
    </div>
  );
}

export default UsersMessage;
