import { useState } from "react";

import { useToggleSidebar } from "../../../../contexts/toggleSidebar";

import Confirm from "../confirm";
import Ads from "../creative";

import { IBannerBearImagesResponse } from "../../../../services/wizard/bannerbear";

type TTabsProps = "campaign" | "creative";

interface ITabViewProps {
  step: number;
  setStep: (step: number) => void;
  creatives: IBannerBearImagesResponse | undefined;
}

function TabView({ step, setStep, creatives }: ITabViewProps) {
  const { isSidebarOpen } = useToggleSidebar();

  const [activeTab, setActiveTab] = useState<TTabsProps>("campaign");

  function handleActiveTab(tab: TTabsProps) {
    setActiveTab(tab);
  }

  return (
    <div className={`${isSidebarOpen ? "ml-8" : "ml-[5.5rem] "}`}>
      <div className="flex shadow-sm rounded-md  bg-white w-[400px] mt-8 border border-solid border-gray-200">
        <div
          onClick={() => handleActiveTab("campaign")}
          className={`w-[50%] cursor-pointer transition-all  delay-75 ease-in  px-3 py-1 ${
            activeTab === "campaign"
              ? "bg-white  text-primary font-interSemiBold "
              : "bg-gray-50 text-gray-300 font-interRegular"
          }`}
        >
          Campaign Details
        </div>
        <div
          onClick={() => handleActiveTab("creative")}
          className={`w-[50%] cursor-pointer transition-all delay-75  ease-in  px-3 py-1 ${
            activeTab === "creative"
              ? "bg-white  text-primary font-interSemiBold "
              : "bg-gray-50 text-gray-300 font-interRegular"
          }`}
        >
          Creative
        </div>
      </div>
      <div
        className={`${isSidebarOpen ? "-ml-8" : "-ml-[5.4rem]"} -mt-[2.09rem]`}
      >
        {activeTab === "campaign" && <Confirm step={step} setStep={setStep} />}
        {activeTab === "creative" && <Ads adsFromEach={creatives} />}
      </div>
    </div>
  );
}

export default TabView;
