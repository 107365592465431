import React, { useState } from "react";

import { RegisterOptions, UseFormRegister } from "react-hook-form";

import Tooltip from "../../components/tooltip";

import { ReactComponent as TooltipIcon } from "../../assets/icons/tooltip-icon-mui.svg";
import HideIcon from "../../assets/icons/hide.png";
import HiddenIcon from "../../assets/icons/hidden.png";

interface InputWithToggleProps {
  className?: string;
  type: string;
  name: string;
  label?: string;
  value?: any;
  defaultValue?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  reactHooksFormEnabled?: boolean;
  register?: UseFormRegister<any>;
  pattern?: {
    value: RegExp;
    message: string;
  };
  requiredMessage?: string;
  validation?: any;
  placeholder?: string;
  labelClassName?: string;
  validationClassName?: string;
  isRequiredField?: boolean;
  customWidth?: string;
  customInputLabelContainer?: string;
  customPadding?: string;
  onBlurFunc?: () => void;
  minValue?: number;
  toolTipEnabled?: boolean;
  tooltipText?: string;
}

const InputWithToggle: React.FC<InputWithToggleProps> = ({
  className,
  type,
  name,
  label,
  value,
  onChange,
  disabled,
  reactHooksFormEnabled,
  register,
  requiredMessage,
  pattern,
  validation,
  defaultValue,
  placeholder,
  labelClassName,
  validationClassName,
  isRequiredField,
  customWidth,
  customInputLabelContainer,
  customPadding,
  onBlurFunc,
  minValue,
  toolTipEnabled,
  tooltipText,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const validationOptions = {} as RegisterOptions;

  if (requiredMessage) {
    validationOptions.required = { value: true, message: requiredMessage };
  }

  if (onBlurFunc) {
    validationOptions.onBlur = onBlurFunc;
  }

  if (pattern) {
    validationOptions.pattern = {
      value: pattern.value,
      message: pattern.message,
    };
  }

  if (type === "number") {
    validationOptions.valueAsNumber = true;
    if (minValue) {
      validationOptions.min = {
        value: minValue,
        message: `Value must be at least ${minValue}`,
      };
    }
  }

  return (
    <div className="flex flex-col ">
      {label && (
        <div className="flex">
          <div></div>
          <label
            className={`${
              labelClassName && labelClassName
            } font-interRegular text-sm text-secondary mb-1`}
          >
            {label}
            {!requiredMessage && <span className="ml-1">(optional)</span>}
            {isRequiredField && <span className="required-field ml-2"></span>}
          </label>
          {toolTipEnabled && tooltipText && (
            <Tooltip
              Icon={TooltipIcon}
              bodyText={tooltipText}
              className=" w-4 h-4 mt-0.5  ml-1.5 cursor-pointer fill-current text-gray-400"
            />
          )}
        </div>
      )}
      <div
        className={`flex items-center ${
          customInputLabelContainer && customInputLabelContainer
        }`}
      >
        <div
          className={`relative ${
            customWidth ? customWidth : "xs:w-[18.75rem] sm:w-[27.5rem]"
          } `}
        >
          {reactHooksFormEnabled && register ? (
            <input
              defaultValue={defaultValue}
              type={
                type === "password"
                  ? isPasswordVisible
                    ? "text"
                    : "password"
                  : type
              }
              className={`${className} ${
                customPadding ? customPadding : "p-3"
              } focus:outline-none font-interRegular ${
                disabled ? "text-[#8d939d] cursor-not-allowed" : "text-primary"
              } text-sm  ${
                validation ? "border-[#D34638]" : "border-[#40444f]"
              } border-[1px] rounded-[0.625rem] w-full`}
              {...register(name, validationOptions)}
              disabled={disabled}
            />
          ) : (
            <input
              type={
                type === "password"
                  ? isPasswordVisible
                    ? "text"
                    : "password"
                  : type
              }
              name={name}
              placeholder={placeholder}
              className={`${className} px-3  ${
                disabled ? "text-[#8d939d] cursor-not-allowed" : "text-primary"
              } focus:outline-none py-3 font-interRegular text-sm  ${
                validation ? "border-[#D34638]" : "border-[#40444f]"
              } border-[1px] rounded-[0.625rem]  ${
                customWidth ? customWidth : "xs:w-[18.75rem] sm:w-[27.5rem]"
              }`}
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              disabled={disabled}
            />
          )}
          {type === "password" && (
            <img
              src={isPasswordVisible ? HideIcon : HiddenIcon}
              alt="password visibility"
              className="absolute right-4 top-3.5 cursor-pointer h-4 w-4 "
              onClick={togglePasswordVisibility}
            />
          )}
        </div>
        {validation && (
          <p
            className={`${
              validationClassName ? validationClassName : "mt-3"
            }  font-interSemiBold text-colorDelete text-sm`}
          >
            {validation}
          </p>
        )}
      </div>
    </div>
  );
};

export default InputWithToggle;
