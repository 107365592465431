import React from "react";

interface StepperProps {
  currentStep: number;
  title: string;
  index: number;
  totalSteps: number;
}

const SidebarStep: React.FC<StepperProps> = ({
  currentStep,
  title,
  index,
  totalSteps,
}) => {
  return (
    <div className="flex flex-col w-full  justify-center my-2  relative">
      {index <= totalSteps - 1 && (
        <div
          className={`h-1 mr-2 ${
            currentStep > index + 2
              ? "bg-blue-500 w-[98%]"
              : currentStep === index + 2
              ? "bg-gray-300 w-[98%]"
              : "bg-gray-300 w-[98%] "
          }`}
        ></div>
      )}
      <div className="flex w-full  h-7 items-center">
        <div
          className={` relative rounded-full flex-col flex ${
            currentStep === index + 2
              ? "bg-blue-500 w-[1.2rem] -ml-[2px]  h-[1.1rem]"
              : currentStep > index + 2
              ? "bg-blue-400 h-4 w-4"
              : "bg-gray-200 h-4 w-4"
          }`}
        >
          {currentStep === index + 2 && (
            <div className="bg-white absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2 rounded-full w-2 h-2"></div>
          )}
          {currentStep > index + 2 && (
            <svg
              className="w-4 h-4 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M5 13l4 4L19 7"></path>
            </svg>
          )}
        </div>

        <div
          className={`
        ${
          currentStep > index + 2
            ? "text-blue-500 font-ManropeSemiBold"
            : currentStep === index + 2
            ? "text-black font-ManropeSemiBold"
            : "text-gray-300 font-ManropeRegular"
        }
        ml-1.5 text-sm text-center
        `}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default SidebarStep;
