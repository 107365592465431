import { useNavigate } from "react-router-dom";

import ContentContainer from "../../components/contentContainer";
import RoundedButton from "../../components/roundedButton";

function NoCampaignView() {
  const navigate = useNavigate();

  return (
    <ContentContainer>
      <div className=" flex flex-1 flex-col  min-h-[36.125rem] max-h-[67rem] pt-[5.6rem] xs:px-2 md:px-6  lg:pl-9 lg:pr-20">
        <div className="flex flex-1 flex-col w-full space-y-8">
          <p className="text-sm text-primary font-interRegular">
            Welcome to your campaign overview!{" "}
          </p>
          <p className="text-sm text-primary font-interRegular">
            It’s a bit quiet here right now, but that just means you’ve got a
            blank canvas ready to bring your advertising dreams to life. Whether
            you're looking to launch a brand-new campaign, experiment with fresh
            ideas, or reach new audiences, this is where the magic happens.{" "}
          </p>
          <p className="text-sm text-primary font-interRegular">
            Ready to kick things off? Tap the “+Campaign” button to get started.
            With just a few clicks, you'll be well on your way to creating a
            campaign that captivates, engages, and delivers real results. Let's
            turn your vision into action and watch your brand shine brighter
            than ever before
          </p>
          <div className="mt-10">
            <RoundedButton
              text={
                <p className=" text-sm text-white inline-flex items-center font-interRegular">
                  <span className="text-2xl   mr-2 text-white">+</span>
                  Campaign
                </p>
              }
              onClick={() => {
                navigate("/campaigns/wizard");
              }}
              bgColor="bg-[#3077aa]"
              borderRadius="rounded-lg"
              textColor="text-secondary"
              className=" mx-auto px-6 py-1 text-sm font-interSemiBold"
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
}

export default NoCampaignView;
