import { queryClient } from "../../App";

import { useMutation } from "@tanstack/react-query";

import { request } from "../axios";

export interface IAssignAdvertiserReq {
  advertiserId: number | null;
  email: string;
}

function assignAdvertiserAdmin(data: IAssignAdvertiserReq) {
  return request({
    url: "user-organization-advertiser-role/assign-advertiser-admin-by-email",
    method: "POST",
    data,
  });
}

export const useAssignAdvertiserAdmin = () => {
  return useMutation({
    mutationFn: assignAdvertiserAdmin,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["allUsers"],
      });
    },
  });
};

function assignAdvertiserBasic(data: IAssignAdvertiserReq) {
  return request({
    url: "user-organization-advertiser-role/assign-advertiser-basic/",
    method: "POST",
    data,
  });
}

export const useAssignAdvertiserBasic = () => {
  return useMutation({
    mutationFn: assignAdvertiserBasic,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["allUsers"],
      });
    },
  });
};
