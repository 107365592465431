import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ColDef } from "ag-grid-community";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";
import { useCampaign } from "../../contexts/campaign";
import { useStep } from "../../contexts/stepper";

import NoAdvertiserCampaignView from "./noAdvertiserCampaignView";
import NoCampaignView from "./noCampaignView";
import CampaignListView from "./campaignListView";

import RoundedButton from "../../components/roundedButton";
import Modal from "../../components/modal";
import TopContent from "../../components/topContent";
import Tooltip from "../../components/tooltip";
import ActivitySpinnerNoBg from "../../components/activitySpinner/withNoBgEffect";
import ActivityIndicator from "../../components/activitySpinner";

// import { ReactComponent as PdfIcon } from "../../assets/icons/pdf-icon-mui.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon-mui.svg";
import { ReactComponent as DisplayIcon } from "../../assets/icons/visibility-icon-mui.svg";
import { ReactComponent as ActiveIcon } from "../../assets/icons/check-circle-mui.svg";
import { ReactComponent as DraftIcon } from "../../assets/icons/not-started-mui.svg";
import { ReactComponent as CompleteIcon } from "../../assets/icons/checkbox-mui.svg";
import { ReactComponent as PendingIcon } from "../../assets/icons/pending-mui.svg";

import { useAllCampaigns, useRemoveCampaign } from "../../services/campaigns";
import { useAllAdvertisers } from "../../services/advertiser";
import { useKeywords } from "../../services/wizard/campaign";

const Index = () => {
  const navigate = useNavigate();
  const { selectedAdvertiser } = useSelectedAdvertiser();

  const { setStep } = useStep();
  const { campaignId, setCampaignId } = useCampaign();

  const [filterStatus, setFilterStatus] = useState<string | null>(null);

  const [deletionModal, setDeletionModal] = useState<boolean>(false);

  const [itemIdToDelete, setItemIdToDelete] = useState<number | undefined>(
    undefined
  );

  const { data: allAdvertisers } = useAllAdvertisers({
    page: 0,
    limit: 1,
  });

  const [dataPageNumber, setDataPageNumber] = useState<number>(0);
  const numberOfItemsInEachPage = 10;

  const {
    data: campaignsList,
    isPending: isLoadingCampaignList,
    isFetching: isCampaignListFetching,
  } = useAllCampaigns(selectedAdvertiser, {
    limit: numberOfItemsInEachPage,
    page: dataPageNumber,
  });

  const {
    isSuccess: isCampaignRemoved,
    isError: isCampaignFailedToRemove,
    mutateAsync: removeCampaign,
  } = useRemoveCampaign(selectedAdvertiser);

  const { data: keywordsData } = useKeywords(campaignId);
  let formattedKeywords = "";

  if (keywordsData && keywordsData[0]?.keywords) {
    formattedKeywords = keywordsData[0].keywords
      .map((keyword) => `• ${keyword} `)
      .join(" ");

    if (formattedKeywords.length > 500) {
      formattedKeywords = formattedKeywords.slice(0, 500 - 3).trim() + "...";
    }
  }

  const [isPDFPending] = useState<boolean | undefined>(undefined);

  async function handleDelete(id: number) {
    await removeCampaign(id);
    setDeletionModal(false);
  }

  function handleDeletionModal(id: number) {
    setItemIdToDelete(id);
    setDeletionModal(true);
  }

  const [gridApi, setGridApi] = useState<any>(null);
  const [tableTotalPages, setTableTotalPages] = useState<number>(0);
  const columnData: ColDef[] = [
    {
      headerName: "NAME",
      cellClass: "align-start",
      headerClass: "custom-table-header-padding",
      sortable: false,
      flex: 3,
      cellRenderer: (params: any) => {
        const { name, goal, budget } = params.data;
        return (
          <div className="flex flex-col">
            <div className="flex">
              <p className="text-primary text-sm font-interSemiBold">{name}</p>
            </div>

            <div className="flex mt-1">
              <p className="text-primary  text-xs inline-flex font-interRegular">
                • Goal:
                <span className="capitalize font-interRegular">
                  {goal.toLowerCase()}
                </span>
              </p>
              <p className="text-primary text-xs inline-flex ml-2 font-interRegular">
                • 30 day cost: ${budget}
              </p>
            </div>
          </div>
        );
      },
      resizable: false,
    },
    {
      headerName: "STATUS",
      field: "status",
      filter: true,
      headerClass: "ag-header-border",
      cellClass: "ag-grid-table-uppercase",
      sortable: false,
      flex: 1,
      resizable: false,
      cellRenderer: (params: any) => {
        const { status } = params.data;
        return (
          <div className="flex items-center">
            <div className="mr-1.5">
              {status === "active" ? (
                <ActiveIcon className="w-7 h-9 text-colorGreen fill-current" />
              ) : status === "draft" ? (
                <DraftIcon className="w-7 h-9 text-colorOrange fill-current" />
              ) : status === "complete" ? (
                <CompleteIcon className="w-7 h-9 text-black fill-current" />
              ) : (
                status === "pending" && (
                  <PendingIcon className="w-7 h-9 text-colorGreen fill-current" />
                )
              )}
            </div>
            <p className="text-primary text-sm font-interRegular capitalize">
              {status}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "ACTION",
      sortable: false,
      // flex: 1,
      width: 120,
      cellRenderer: (params: any) => {
        return (
          <div className="flex">
            <div
              className="cursor-pointer"
              onClick={() => {
                setCampaignId(params.data.id);
                setStep(1);
                navigate("/campaigns/wizard");
              }}
            >
              {
                <Tooltip
                  Icon={DisplayIcon}
                  bodyText="Campaign Details"
                  className="w-6 h-6 text-gray-500 fill-current"
                />
              }
            </div>
            {/* <div
              className="ml-1.5 cursor-pointer"
              onClick={() => {
                setCampaignId(params.data.id);
                setStep(5);
                navigate("/campaigns/wizard");
              }}
            >
              {
                <Tooltip
                  Icon={PdfIcon}
                  bodyText="Download PDF"
                  className="w-6 h-6 text-gray-500 fill-current"
                />
              }
            </div> */}
            <div
              onClick={() => handleDeletionModal(params.data.id)}
              className="ml-1.5 cursor-pointer"
            >
              {
                <Tooltip
                  Icon={DeleteIcon}
                  bodyText="Delete"
                  className="w-6 h-6 text-gray-500 fill-current"
                />
              }
            </div>
          </div>
        );
      },
      resizable: false,
    },
  ];

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
  }, []);

  function onDropdownChange(value: string | null) {
    if (gridApi) {
      if (value === "active" || value === "draft") {
        gridApi.setFilterModel({
          status: {
            filterType: "text",
            type: "equals",
            filter: value,
          },
        });
      } else {
        gridApi.setFilterModel(null);
      }
    }
  }

  useEffect(() => {
    if (isCampaignRemoved) {
      toast.success("Campaign deleted successfully!");
    } else if (isCampaignFailedToRemove) {
      toast.error("Failed to remove the campaign");
    }
  }, [isCampaignRemoved, isCampaignFailedToRemove]);

  useEffect(() => {
    if (gridApi && campaignsList?.data && campaignsList.data?.length > 0) {
      const totalPages = campaignsList.pagination.totalPages;
      setTableTotalPages(totalPages);
    }
    // eslint-disable-next-line
  }, [gridApi, campaignsList?.data.length, filterStatus]);

  // console.log(campaignsList?.pagination);

  useEffect(() => {
    if (isCampaignListFetching && gridApi) {
      gridApi.showLoadingOverlay();
    } else if (gridApi) {
      gridApi.hideOverlay();
    }
  }, [isCampaignListFetching, gridApi]);

  return (
    <div className="w-full flex flex-col">
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      {deletionModal && (
        <Modal
          setIsModalOpen={setDeletionModal}
          modalBodyContent={
            <div className="flex flex-col p-1">
              <p className="font-ManropeSemiBold">
                Are you sure you want to delete this campaign ?
              </p>
              <div className="flex mt-5 justify-center">
                <RoundedButton
                  text="yes"
                  className="py-2 px-6 ManropeRegular mr-2"
                  onClick={() => {
                    if (itemIdToDelete !== undefined) {
                      handleDelete(itemIdToDelete);
                      setItemIdToDelete(undefined);
                    }
                  }}
                />
                <RoundedButton
                  text="no"
                  className="py-2 px-6 font-ManropeRegular bg-gray-300"
                  onClick={() => setDeletionModal(false)}
                />
              </div>
            </div>
          }
        />
      )}

      <TopContent
        title="Campaign Overview"
        subTitle="Manage and track all advertising campaigns"
      />
      {isPDFPending && <ActivityIndicator />}
      {isLoadingCampaignList && isCampaignListFetching ? (
        <ActivitySpinnerNoBg />
      ) : (
        <>
          <div>
            {!selectedAdvertiser && allAdvertisers?.data?.length === 0 ? (
              <NoAdvertiserCampaignView />
            ) : !isCampaignListFetching &&
              campaignsList &&
              campaignsList.data?.length === 0 ? (
              <NoCampaignView />
            ) : (
              allAdvertisers?.data?.length !== 0 &&
              selectedAdvertiser &&
              campaignsList &&
              campaignsList?.data.length > 0 && (
                <CampaignListView
                  tableTotalPages={tableTotalPages}
                  setDataPageNumber={setDataPageNumber}
                  setFilterStatus={setFilterStatus}
                  onDropdownChange={onDropdownChange}
                  gridApi={gridApi}
                  numberOfItemsInEachPage={numberOfItemsInEachPage}
                  onGridReady={onGridReady}
                  columnData={columnData}
                  campaignsList={campaignsList}
                />
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Index;
