import { useNavigate } from "react-router-dom";

import ContentContainer from "../../components/contentContainer";
import RoundedButton from "../../components/roundedButton";

import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon-mui.svg";

function NoAdvertiserCampaignView() {
  const navigate = useNavigate();

  return (
    <ContentContainer>
      <div className=" flex flex-1 flex-col  min-h-[36.125rem] max-h-[67rem] pt-[5.6rem] xs:px-2 md:px-6  lg:pl-9 lg:pr-20">
        <div className="flex flex-1 flex-col w-full space-y-8">
          <p className="text-sm text-primary font-interRegular">
            Welcome to your campaign dashboard! 
          </p>
          <p className="text-sm text-primary font-interRegular">
            Looks like you're ready to start creating your campaign—awesome!
            Before we dive in, you’ll need to add an advertiser to get things
            rolling.
          </p>
          <p className="text-sm text-primary font-interRegular">
            Tap the 'Add Advertiser' button to get started, and then you’ll be
            all set to create your campaign!"
          </p>
          <div className="mt-10">
            <RoundedButton
              text={
                <p className=" text-sm text-white inline-flex items-center font-interRegular">
                  <EditIcon className="text-white fill-current mr-2 text-2xl " />
                  Advertiser
                </p>
              }
              onClick={() => {
                navigate("/advertiser/addAdvertiser");
              }}
              bgColor="bg-[#3077aa]"
              textColor="text-secondary"
              className="py-2.5 px-4 mx-auto mt-5"
              borderRadius="rounded-md"
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
}

export default NoAdvertiserCampaignView;
