import { useEffect, useState } from "react";

import CurrentProgressStep from "./currentProgressStep";

function ProgressTracking({
  steps,
  isRequestStillProcessing,
}: {
  steps: string[];
  isRequestStillProcessing?: boolean;
}) {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prev) => (prev <= steps.length - 1 ? prev + 1 : prev));
    }, 6000);

    return () => clearInterval(interval);
  }, [steps.length]);

  return (
    <div className="fixed inset-0 z-30 bg-white/80 backdrop-blur-sm flex items-center justify-center">
      <div className="flex justify-center items-center h-screen w-full ">
        <div className="w-4/5 max-w-xl">
          {steps.map((step, index) => (
            <CurrentProgressStep
              key={step}
              index={index}
              step={step}
              currentStep={currentStep}
            />
          ))}
          {currentStep === steps.length && isRequestStillProcessing && (
            <p
              style={{
                animation: "gradient 6s infinite, fadeIn 0.5s ease-in",
              }}
              className="bg-gradient-to-r text-2xl mt-[3.2rem] text-center from-pink-500 via-red-500 to-yellow-500 text-transparent bg-clip-text text-transparent-custom animate-gradient"
            >
              Hold on tight, you are almost there
              <span className="dots ">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProgressTracking;
