import { ICampaignStrategyResponse } from "../../services/campaigns";

export default function EstimationTable({
  campaignStrategyData,
  className,
}: {
  campaignStrategyData: ICampaignStrategyResponse | undefined;
  className: string;
}) {
  return (
    <div className={className}>
      <div className="w-full rounded-md border-solid border border-[#E5E9EB]">
        <div className="py-3 border-b border-solid border-[#E5E9EB] rounded-md ">
          <p className="text-primary text-sm font-interRegular text-center">
            {campaignStrategyData?.campaignGoal.value === "Website Visits"
              ? "Estimated visits (daily)"
              : "Estimated views (daily)"}
          </p>
        </div>

        <div className="flex">
          <div className="text-center w-1/2 mr-0.5 border rounded-md py-6 border-solid border-[#E5E9EB]">
            <p className=" text-colorGreen text-4xl font-interRegular">
              {campaignStrategyData?.campaignBudgetEstimation.high}
            </p>
            <p className="text-colorGreen text-base font-interRegular">HIGH</p>
          </div>
          <div className="text-center w-1/2  border rounded-md py-6 border-solid border-[#E5E9EB]">
            <p className="text-colorDelete text-4xl font-interRegular">
              {campaignStrategyData?.campaignBudgetEstimation.low}
            </p>
            <p className="text-colorDelete text-base font-interRegular">LOW</p>
          </div>
        </div>

        <div className=" border py-2 px-1 border-solid border-[#E5E9EB] rounded-md ">
          <p className="text-xs text-primary font-interRegular">
            These estimates are based on industry averages and may vary due to
            factors like seasonality, competition, and location.
          </p>
        </div>
      </div>
    </div>
  );
}
