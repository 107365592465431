import { useEffect, useState } from "react";

export default function BotMessage({
  fetchMessage,
}: {
  fetchMessage: () => Promise<string>;
}) {
  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    async function loadMessage() {
      const msg = await fetchMessage();
      setLoading(false);
      setMessage(msg);
    }
    loadMessage();
  }, [fetchMessage]);

  return (
    <div className="w-full">
      <div
        style={{ borderRadius: "20px 20px 20px 1px" }}
        className="float-left py-4 px-5 my-1 ml-2 mr-5 bg-[#29209e] text-white min-w-10"
      >
        {isLoading ? "..." : message}
      </div>
    </div>
  );
}
