import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// import html2canvas from "html2canvas";
// import { toPng } from "html-to-image";
import domtoimage from "dom-to-image";

import { useCampaign } from "../../../../contexts/campaign";
import { useSelectedAdvertiser } from "../../../../contexts/selectedAdvertiser";
import { useDomain } from "../../../../contexts/subDomainData";

import { categorizeFields } from "../targeting/categorizeFields";
import { SavePDF } from "../../../campaigns/campaignDetails/pdfDocument";
import AddressMap from "../../../map";

import ContentContainer from "../../../../components/contentContainer";
import RoundedButton from "../../../../components/roundedButton";
import PieChart from "../../../../components/pieChart";
import ActivitySpinnerNoBg from "../../../../components/activitySpinner/withNoBgEffect";
import ActivityIndicator from "../../../../components/activitySpinner";
import Tooltip from "../../../../components/tooltip";
import EstimationTable from "../../../../components/estimationTable";

import { useGeocoding } from "../../../../hooks/useGeoCoding";

import { ReactComponent as PdfIcon } from "../../../../assets/icons/pdf-icon-mui.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon-mui.svg";
// import { ReactComponent as ShareIcon } from "../../../../assets/icons/share-icon-mui.svg";

import {
  ICampaignStrategyResponse,
  useCampaignStrategyInfo,
  useSingleCampaignDetails,
  useUpdateCampaign,
} from "../../../../services/campaigns";
import { useAdvertiser } from "../../../../services/advertiser";
import { useKeywords } from "../../../../services/wizard/campaign";
import { useBudgetOrganizationChannels } from "../../../../services/advertiser/organization-budget-channel";

import { parseDefaultVal } from "../../../../utils/parseDefaultValues";
import { formatDateWithTime } from "../../../../utils/formatDateWithTime";

import { BUDGET_CHANNEL_MAPPING_READ_MODE } from "../../../../constants/wizard/campaign";

const Confirm = ({
  step,
  setStep,
}: {
  step: number;
  setStep: (step: number) => void;
}) => {
  const navigate = useNavigate();

  const { campaignId } = useCampaign();
  const { selectedAdvertiser } = useSelectedAdvertiser();

  const {
    mutateAsync: updateCampaign,
    isPending: isCampaignUpdatePending,
    isError: isCampaignUpdateFailed,
    isSuccess: isCampaignUpdated,
  } = useUpdateCampaign();

  const {
    isError: isCampaignDataFailed,
    isPending: isCampaignDataLoading,
    data: campaignData,
  } = useSingleCampaignDetails(campaignId, selectedAdvertiser);

  const { data: strategyInfo } = useCampaignStrategyInfo(
    campaignId,
    selectedAdvertiser
  );
  const campaignStartDate = new Date(strategyInfo?.campaignStartDate.value);
  const campaignEndDate = new Date(strategyInfo?.campaignEndDate.value);
  const campaignDurationInMilliseconds =
    campaignEndDate.getTime() - campaignStartDate.getTime();
  const campaignDurationInDays =
    campaignDurationInMilliseconds / (1000 * 60 * 60 * 24);

  const totalBudget =
    campaignData?.campaignBudget &&
    Math.ceil(
      (campaignData?.campaignBudget.budget / 30) * campaignDurationInDays
    );

  const { data: campaignOwnerData } = useAdvertiser(selectedAdvertiser);

  const { domainName, domainStyleData } = useDomain();
  const { data: organizationBudgetChannels } = useBudgetOrganizationChannels(
    domainStyleData?.id ?? null,
    domainName
  );

  const categorizedFields = categorizeFields(
    campaignData?.campaignFieldValues,
    true
  );
  const generalCampaignInfoArr = categorizedFields["GENERAL"];
  const ADVNACED_TARGETING_DATA =
    categorizedFields && categorizedFields["ADVANCE_TARGETING"];
  const GEO_TARGETING_DATA =
    categorizedFields && categorizedFields["GEO_TARGETING"];

  const addressField = GEO_TARGETING_DATA?.find(
    (field) => field.campaignFieldName.toLowerCase() === "address"
  );

  const radiusField = GEO_TARGETING_DATA?.find(
    (field) => field.campaignFieldName.toLowerCase() === "radius"
  );

  const { geoCodingResult } = useGeocoding({ address: addressField?.value });

  let campaignWebsite = campaignData?.websiteUrl;

  const { data: keywordsData } = useKeywords(campaignId);
  let formattedKeywords = "";

  if (keywordsData && keywordsData[0]?.keywords) {
    formattedKeywords = keywordsData[0].keywords
      .map((keyword) => `• ${keyword} `)
      .join(" ");

    if (formattedKeywords.length > 500) {
      formattedKeywords = formattedKeywords.slice(0, 500 - 3).trim() + "...";
    }
  }

  const campaignDisplayImage =
    campaignData?.campaignImages?.displayAds[0]?.imageUrl;
  const campaignDisplayImage2 =
    campaignData?.campaignImages?.displayAds[1]?.imageUrl;
  const campaignSocialImages =
    campaignData?.campaignImages?.socialAds[0]?.imageUrl;
  const campaignSearchImages =
    campaignData?.campaignImages?.searchAds[0]?.imageUrl;

  const chartRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<HTMLDivElement | null>(null);
  const [pieChartImageSrc, setPieChartImageSrc] = useState("");
  const [mapImageSrc, setMapImageSrc] = useState<string | undefined>("");
  const legalText = domainStyleData?.termsAndConditions;

  const [isPDFPending, setIsPDFPending] = useState<undefined | boolean>(
    undefined
  );

  const campaignProps = {
    legalText,
    setIsPDFPending,
    mapImageSrc,
    pieChartImageSrc,
    generalCampaignInfoArr,
    advanceTargetingCampaignInfoArr: ADVNACED_TARGETING_DATA,
    geoTargetingCampaignInfoArr: GEO_TARGETING_DATA,
    campaignName: campaignData?.name,
    campaignOwnerData,
    totalBudget,
    campaignData,
    campaignKeywordsFormatted: formattedKeywords,
    campaignDisplayImage,
    campaignDisplayImage2,
    campaignSocialImages,
    campaignSearchImages,
  };

  useEffect(() => {
    if (chartRef.current && campaignData?.campaignBudget?.budget) {
      domtoimage.toPng(chartRef.current).then((dataUrl) => {
        setPieChartImageSrc(dataUrl);
      });
    }
  }, [chartRef, campaignData?.campaignBudget?.budget]);

  useEffect(() => {
    if (isCampaignUpdated) {
      navigate("/campaigns");
    }
  }, [isCampaignUpdated, navigate]);

  let lat, long;
  let mapUrl: null | string = null;

  if (geoCodingResult?.geometry && addressField) {
    lat = geoCodingResult.geometry.location.lat();
    long = geoCodingResult.geometry.location.lng();
    const encodedAddress = encodeURI(addressField.value);
    mapUrl = `https://maps.googleapis.com/maps/api/staticmap?&size=1280x1280&center=${lat},${long}&zoom=9&maptype=roadmap&markers=size:mid%7Ccolor:red%7C${encodedAddress}&key=AIzaSyDKVLTjkjPNb1la_wtdbnlBc-yFhRI6BLk`;
  }

  useEffect(() => {
    if (mapUrl) {
      setMapImageSrc(mapUrl);
    }
  }, [mapUrl]);

  return isCampaignDataLoading ? (
    <ActivitySpinnerNoBg />
  ) : isCampaignDataFailed ? (
    <div>
      <p className="text-colorDelete text-sm font-interSemiBold">
        Something went wrong when trying to load campaign data
      </p>
    </div>
  ) : (
    campaignData && (
      <ContentContainer className="px-7 py-5">
        {isCampaignUpdatePending && <ActivityIndicator />}
        {isPDFPending && <ActivityIndicator />}
        <div className="flex flex-col space-y-4">
          <section id="generalCampaignInfo">
            <div className="flex items-center">
              <div className="flex ml-auto items-center">
                {campaignData?.status !== "active" && step === 1 && (
                  <div
                    onClick={() => {
                      setStep(2);
                    }}
                  >
                    <Tooltip
                      Icon={EditIcon}
                      bodyText="Edit"
                      className="w-9 h-8  text-gray-500 cursor-pointer  fill-current"
                    />
                  </div>
                )}
                <div
                  className="mx-6"
                  onClick={() => {
                    if (pieChartImageSrc && mapImageSrc) {
                      setIsPDFPending(true);
                      SavePDF({ ...campaignProps });
                    }
                  }}
                >
                  <Tooltip
                    Icon={PdfIcon}
                    bodyText="Download PDF"
                    className="w-9 h-8  text-gray-500 cursor-pointer  fill-current"
                  />
                </div>
                {step === 1 && (
                  <div>
                    <RoundedButton
                      onClick={async () => {
                        if (
                          campaignData.status &&
                          campaignData.status !== "active"
                        ) {
                          await updateCampaign({
                            campaignId,
                            data: { status: "active" },
                          });
                          return;
                        }
                        navigate("/campaigns");
                      }}
                      type="button"
                      isEnabled={true}
                      className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
                      borderRadius="rounded-md"
                      bgColor={`${
                        campaignData.status !== "active"
                          ? "bg-[#49AC1B]"
                          : "bg-[#3077aa]"
                      }`}
                      text={
                        <p className=" text-sm text-white inline-flex items-center font-interRegular">
                          {campaignData?.status === "active"
                            ? "Go Back to Campaigns"
                            : isCampaignUpdatePending
                            ? "Processing..."
                            : "Start my Campaign"}
                        </p>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <hr className=" mt-1.5 mb-3" />

            <div className="flex w-full mt-6">
              <div className="flex w-[55%] flex-col">
                <h3 className="text-sm w-full mb-2.5 font-interSemiBold text-primary">
                  Campaign Info
                </h3>

                <div>
                  <p className="font-interRegular text-sm text-primary">
                    <span className="mr-1 text-sm font-interSemiBold text-primary">
                      • Campaign name:
                    </span>
                    {strategyInfo?.campaignName}
                  </p>

                  {generalCampaignInfoArr?.map((campaignFieldsObj) => {
                    const fieldTitle = campaignFieldsObj.campaignFieldName;
                    const fieldVal = campaignFieldsObj.campaignFieldName
                      .toLocaleLowerCase()
                      .includes("date")
                      ? formatDateWithTime(campaignFieldsObj.value)
                      : campaignFieldsObj.value;

                    return (
                      <p
                        key={campaignFieldsObj.campaignFieldId}
                        className="font-interRegular my-2 text-primary text-sm"
                      >
                        <span className="mr-1 text-sm font-interSemiBold text-primary">
                          • {fieldTitle}:
                        </span>
                        {fieldVal}
                      </p>
                    );
                  })}

                  <p className="font-interRegular my-2 text-primary text-sm">
                    <span className="mr-1 text-sm font-interSemiBold text-primary">
                      • Campaign Website Url:
                    </span>
                    {campaignWebsite}
                  </p>

                  <p className="font-interRegular my-2 text-primary text-sm">
                    <span className="mr-1 text-sm font-interSemiBold text-primary">
                      • Total Budget:
                    </span>
                    ${totalBudget}
                  </p>
                </div>
              </div>
              <div className="flex w-[23rem] ml-auto">
                <EstimationTable
                  className="w-full"
                  campaignStrategyData={strategyInfo}
                />
              </div>
            </div>
          </section>

          <section id="allocation">
            <h3 className="text-sm text-primary mb-3 font-interSemiBold mt-6">
              Allocation
            </h3>
            <div className="flex flex-col ">
              <div className="flex">
                <div className="w-[55%]">
                  <p className="text-primary font-interRegular leading-6 text-sm w-full">
                    Strategic budget allocation is the cornerstone of success
                    for any ad campaign that determines your ROI delivers
                    maximum impact and returns. By carefully analyzing market
                    trends, consumer behavior, and competitive landscapes, your
                    budget allocation plan tailored to your unique business goal
                  </p>
                </div>

                <div className="ml-auto -mt-6">
                  <div ref={chartRef} className="w-[60%]">
                    {strategyInfo && organizationBudgetChannels && (
                      <PieChart
                        topContainer="flex flex-col space-y-5  order-2"
                        indicatorContainer="flex items-center w-28 ml-6"
                        circleContainer="relative w-[16rem]"
                        data={organizationBudgetChannels.map((channel) => {
                          const channelKey = BUDGET_CHANNEL_MAPPING_READ_MODE[
                            channel.budgetChannel
                          ]
                            .key as keyof ICampaignStrategyResponse["campaignBudget"];
                          const channelColor =
                            BUDGET_CHANNEL_MAPPING_READ_MODE[
                              channel.budgetChannel
                            ].color;
                          const value = strategyInfo.campaignBudget[channelKey];

                          return {
                            key: `campaign-strategy-pie-${channelKey}`,
                            value: value,
                            color: channelColor,
                            title: channel.channelName,
                          };
                        })}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="targeting">
            <div className="flex">
              <div className="flex flex-col w-[55%]">
                <h3 className="text-sm text-primary mb-3 font-interSemiBold ">
                  Targeting
                </h3>
                <p className="text-sm text-primary mb-2 font-interRegular">
                  Our targeting strategy is engineered to pinpoint your ideal
                  customers with surgical precision, ensuring every impression
                  counts through a combination of advanced data analytics,
                  demographic profiling, and psychographic segmentation, we've
                  identified the audiences most interested in your business.
                </p>

                <p className="text-sm text-primary mt-1.5 font-interRegular">
                  Your ads will be shown around
                  <span className="font-interSemiBold mx-1.5">
                    {addressField?.value}
                  </span>
                  for a
                  <span className="font-interSemiBold mx-1.5">
                    {radiusField?.value}
                  </span>
                  mile radius targeted for audiences that meet the following
                  traits:
                </p>

                <div className="flex flex-col mt-2">
                  {ADVNACED_TARGETING_DATA?.map((field) => {
                    return (
                      <div key={field.campaignFieldId} className="flex ">
                        <p className="text-sm text-primary font-interRegular">
                          •
                          <span className="mx-1.5 text-sm text-primary font-interSemiBold">
                            {field.campaignFieldName}:
                          </span>
                          {parseDefaultVal(field.value)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                ref={mapRef}
                className="flex ml-auto flex-grow-0 h-[20.625rem] w-[20.625rem] mr-4"
              >
                <AddressMap
                  geoCodingResult={geoCodingResult}
                  radius={radiusField?.value}
                />
              </div>
            </div>
          </section>

          <section id="search-keywords">
            <div>
              <h3 className="text-sm text-primary  font-interSemiBold mt-6">
                Search keywords
              </h3>
              <p className="font-interRegular text-sm text-primary mt-1.5">
                Your ads will appear for users who are actively searching for
                these topics on search engines.
              </p>
              <p className=" font-interSemiBold text-sm text-primary">
                {formattedKeywords}
              </p>
            </div>
          </section>
        </div>
        {domainStyleData?.termsAndConditions && (
          <section className="mt-12" id="legal-text">
            <h3 className="text-sm text-primary mb-3 font-interSemiBold">
              Legal
            </h3>
            <p className="text-sm text-primary font-interRegular">
              {domainStyleData?.termsAndConditions}
            </p>
          </section>
        )}
        {step === 5 && (
          <div className="ml-auto mt-10 flex">
            {isCampaignUpdateFailed && (
              <div className="self-center mr-6">
                <p className="text-sm font-interSemiBold text-colorDelete">
                  Something went wrong with starting your campaign !
                </p>
              </div>
            )}

            <RoundedButton
              onClick={() => setStep(4)}
              type="button"
              className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto mr-5"
              borderRadius="rounded-md"
              bgColor="#FFFFFF"
              borderColor="#C1C9D2"
              text={
                <p className=" text-sm text-primary inline-flex items-center font-interRegular">
                  Previous
                </p>
              }
            />
            <RoundedButton
              onClick={async () => {
                if (campaignData.status && campaignData.status !== "active") {
                  await updateCampaign({
                    campaignId,
                    data: { status: "active" },
                  });
                  return;
                }
                navigate("/campaigns");
              }}
              type="button"
              isEnabled={true}
              className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
              borderRadius="rounded-md"
              bgColor={`${
                campaignData.status !== "active"
                  ? "bg-[#49AC1B]"
                  : "bg-[#3077aa]"
              }`}
              text={
                <p className=" text-sm text-white inline-flex items-center font-interRegular">
                  {campaignData?.status === "active"
                    ? "Go Back to Campaigns"
                    : isCampaignUpdatePending
                    ? "Processing..."
                    : "Start my Campaign"}
                </p>
              }
            />
          </div>
        )}
      </ContentContainer>
    )
  );
};

export default Confirm;
