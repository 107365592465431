import Axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
} from "axios";
import * as AxiosLogger from "axios-logger";

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

export interface HeaderProps extends HeadersDefaults {
  authorization?: string | null;
}

AxiosLogger.setGlobalConfig({
  params: true,
});

// axios.interceptors.request.use(
//   AxiosLogger.requestLogger,
//   AxiosLogger.errorLogger
// );
// axios.interceptors.response.use(
//   AxiosLogger.responseLogger,
//   AxiosLogger.errorLogger
// );

export function request<T = any>(
  requestObject: AxiosRequestConfig<any> = {}
): Promise<T> {
  return new Promise((resolve, reject) => {
    axios(requestObject)
      .then((response: AxiosResponse<T>) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        } else {
          console.error(`Unexpected status code: ${response.status}`);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          reject(
            console.error(
              `Server responded with status: ${error.response.status}`
            )
          );
        } else if (error.request) {
          console.error("No response received from the server");
        } else {
          console.error(error.message);
        }
      });
  });
}

export const getAxiosAuthHeader = () =>
  (axios.defaults.headers as HeaderProps).authorization;
export const setAxiosAuthHeader = (firebaseToken: string) => {
  const headers = axios.defaults.headers as HeaderProps;
  headers.authorization = firebaseToken;
};
export const clearAxiosAuthHeader = () => {
  const headers = axios.defaults.headers as HeaderProps;
  delete headers.authorization;
};
