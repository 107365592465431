import React, { ReactNode, useContext, useState } from "react";
import { generateRandomId } from "../../utils/generateUniqueId";
import { useCookies } from "react-cookie";

interface IChatBot {
  isToggled: boolean;
  setIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
  handleToggle(): void;
}

interface IChatBotProps {
  children: ReactNode;
}

const ChatBotContext = React.createContext({} as IChatBot);

export const ChatBotProvider: React.FC<IChatBotProps> = ({ children }) => {
  const [cookies, setCookie] = useCookies(["chatSessionId"]);
  const [isToggled, setIsToggled] = useState(true);

  function handleToggle() {
    if (isToggled) {
      setUsersSessionTokenForChat();
    }
    setIsToggled((prev) => !prev);
  }

  function setUsersSessionTokenForChat() {
    let sessionId = cookies.chatSessionId;
    if (sessionId) return;

    sessionId = generateRandomId();
    setCookie("chatSessionId", sessionId, {
      path: "/",
      maxAge: 24 * 60 * 60,
      secure: true,
      sameSite: "strict", // Prevent CSRF attacks
    });
  }

  return (
    <ChatBotContext.Provider value={{ isToggled, setIsToggled, handleToggle }}>
      {children}
    </ChatBotContext.Provider>
  );
};

export const useChatBot = () => {
  const context = useContext(ChatBotContext);

  if (context === undefined) {
    throw new Error("useChatBot must be used withing a context");
  }
  return context;
};
