import { ChangeEvent, useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ColDef } from "ag-grid-community";

import ContentContainer from "../../components/contentContainer";
import Table from "../../components/table";
import RoundedButton from "../../components/roundedButton";
import TableImageModal from "../../components/modal/tableImageModal";
import { Selection } from "../../components/select";
import TopContent from "../../components/topContent";

import {
  useUpdateAsset,
  useWebsiteAssets,
} from "../../services/website-assets";

import { formatDate } from "../../utils/formatDate";

import { IAssetData } from "../../interfaces";

const Assets = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const idToNumber = id ? parseInt(id) : null;

  const { data: assetData, isPending: isLoadingAssetData } =
    useWebsiteAssets(idToNumber);

  const { mutateAsync: updateAssets, isSuccess: isUpdatingNewAssetSuccess } =
    useUpdateAsset(idToNumber);

  const [assetRowData, setAssetRowData] = useState<IAssetData[] | []>([]);
  const [, setInitialRowData] = useState<IAssetData[] | []>([]);

  const [modalImage, setModalImage] = useState<string | null>(null);

  const [assetErrorMessage, setAssetErrorMessage] = useState<null | string>(
    null
  );

  const [filterStatus, setFilterStatus] = useState<{
    label: string;
    value: string;
  } | null>({ label: "active", value: "false" });

  const columnData: ColDef[] = [
    {
      headerName: "IMAGE",
      field: "imageUrl",
      cellRenderer: (params: any) => (
        <img
          className="my-1 w-[110px] object-contain"
          src={params.value}
          alt="row.png"
          onClick={() => setModalImage(params.value)}
        />
      ),
      cellStyle: { paddingLeft: "20px", textTransform: "inherit" },
      headerClass: "custom-table-header-padding",
      // headerClass: "ag-header-border",
      // width: 110,
      flex: 1,
      sortable: false,
      suppressSpanHeaderHeight: true,
      resizable: false,
    },
    {
      headerName: "SOURCE",
      field: "method",
      headerClass: "ag-header-border",
      sortable: false,
      // width: 305,
      flex: 1,
      cellRenderer: (params: any) => (
        <h2>
          {" "}
          {params.value === "SYSTEM"
            ? "website"
            : params.value === "STOCK_LIBRARY"
            ? "stock library"
            : "user"}
        </h2>
      ),
      resizable: false,
    },
    {
      headerName: "DATE ADDED",
      headerClass: "ag-header-border",
      field: "createdAt",
      sort: "desc",
      flex: 1,
      cellRenderer: (params: any) => {
        return <p>{formatDate(params.value)}</p>;
      },
      resizable: false,
    },
    {
      headerName: "EXCLUDED FROM CREATIVE",
      field: "isExcluded",
      sort: "asc",
      filter: true,

      width: 250,
      cellStyle: { justifyContent: "center" },
      valueGetter: (params) => {
        const { isExcluded } = params.data;
        return isExcluded ? "true" : "false";
      },
      cellRenderer: (params: any) => {
        return (
          <input
            className="h-5 w-5 accent-green-700  cursor-pointer border-2 border-solid"
            type="checkbox"
            checked={params.value === "true" ? true : false}
            onChange={(e) => {
              handleCheckBox(e, params.node);
            }}
          />
        );
      },
      resizable: false,
    },
  ];
  const [gridApi, setGridApi] = useState<any>(null);
  const [tableTotalPages, setTableTotalPages] = useState<number>(0);
  const pageSize = 15;

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
  }, []);

  async function handleCheckBox(e: ChangeEvent<HTMLInputElement>, params: any) {
    const isExcluded = e.target.checked;

    params.setDataValue("isExcluded", isExcluded);
    params.setSelected(isExcluded);

    try {
      const requestData = { assets: [{ id: params.data.id, isExcluded }] };

      const hasAtleastOneItemNotExcluded = assetRowData.some(
        (rowData) => rowData.isExcluded === false
      );

      if (!hasAtleastOneItemNotExcluded) {
        setAssetErrorMessage("At least 1 asset must be available");
        return;
      }

      await updateAssets(requestData);
    } catch (error) {
      console.error("Can't update the assets:", error);
    }
  }

  const onDropdownChange = (value: string | null) => {
    if (gridApi) {
      if (value) {
        gridApi.setFilterModel({
          isExcluded: {
            filterType: "text",
            type: "equals",
            filter: value,
          },
        });
      } else {
        gridApi.setFilterModel(null);
      }
    }
  };

  useEffect(() => {
    if (gridApi && assetRowData.length > 0) {
      const totalRows = gridApi.paginationGetRowCount();
      const totalPages = Math.ceil(totalRows / pageSize);
      setTableTotalPages(totalPages);
    }
  }, [gridApi, assetRowData]);

  useEffect(() => {
    if (assetData) {
      setAssetRowData([...assetData?.map((item: any) => ({ ...item }))]);
      setInitialRowData(assetData.map((item: any) => ({ ...item })));
    }
  }, [assetData]);

  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        gridApi.forEachNode((node: any) => {
          if (node.data.isExcluded) {
            node.setSelected("true");
          }
        });
      }, 500);
    }
  }, [gridApi, isUpdatingNewAssetSuccess]);

  useEffect(() => {
    if (isLoadingAssetData && gridApi) {
      gridApi.showLoadingOverlay();
    } else if (gridApi) {
      gridApi.hideOverlay();
    }
  }, [isLoadingAssetData, gridApi]);

  useEffect(() => {
    if (gridApi && !filterStatus?.value) {
      gridApi.setFilterModel(null);
    } else if (gridApi && filterStatus?.value) {
      gridApi.setFilterModel({
        isExcluded: {
          filterType: "text",
          type: "equals",
          filter: filterStatus.value,
        },
      });
    }
    // eslint-disable-next-line
  }, [gridApi]);

  return (
    <div className="flex flex-col">
      <TopContent
        title=" Media Library"
        subTitle="Digital assets for creative designs"
      />

      <ContentContainer>
        {modalImage && (
          <TableImageModal
            isOpen={modalImage}
            src={modalImage}
            onClose={() => setModalImage(null)}
          />
        )}

        <div>
          <div className="flex  my-[1px] items-center ">
            <div className="py-2 flex items-center ">
              <p className="mx-5 font-interSemiBold text-sm text-primary">
                Filter by:{" "}
              </p>
              <Selection
                enableDefaultValue={true}
                value={filterStatus}
                data={[
                  {
                    label: "all",
                    value: null,
                  },
                  {
                    label: "inactive",
                    value: "true",
                  },
                  {
                    label: "active",
                    value: "false",
                  },
                ]}
                isMulti={undefined}
                closeMenuOnSelect={true}
                placeholder={"Status"}
                customStyle={{
                  selectContainer: {
                    marginBlock: "-2px",
                    paddingInline: 7,
                    fontSize: 14,
                    fontFamily: "InterRegular",
                    paddingBlock: 0,
                    borderRadius: "7px",
                    width: "15rem",
                    marginRight: ".7rem",
                  },
                  color: "#d3d3d3",
                  optionisSelectedBgColor: "transparent",
                  optionisFocusedBgColor: "#d7edff",
                  optionisFocusedTextColor: "#0e73f6",
                  singleValStyles: {
                    backgroundColor: "transparent",
                  },
                  valueContainer: {
                    fontFamily: "InterRegular",
                  },
                }}
                onChange={(selectedOption: any, actionMeta: any) => {
                  const label = selectedOption ? selectedOption.label : null;
                  const value = selectedOption ? selectedOption.value : null;
                  onDropdownChange(value);
                  setFilterStatus({ label, value });
                }}
              />
            </div>
            <div className="max-w-fit flex items-center  ml-auto">
              {assetErrorMessage && (
                <p className="text-red-400 font-ManropeSemiBold  text-sm mr-5">
                  {assetErrorMessage}
                </p>
              )}
              <RoundedButton
                onClick={() => navigate(`/websites/assets/${id}/addAsset`)}
                text={
                  <p className=" text-sm text-white inline-flex items-center font-interRegular">
                    <span className="text-2xl   mr-2 text-white">+</span>
                    Add Assets
                  </p>
                }
                bgColor="bg-[#3077aa]"
                borderRadius="rounded-lg"
                textColor="text-secondary"
                className=" ml-auto px-6 py-1 text-sm font-interSemiBold mr-1"
              />
            </div>
          </div>

          <Table
            gridApi={gridApi}
            tableTotalPages={tableTotalPages}
            tableId="assetsGrid"
            tableStyle={{
              minHeight: "200px",
              maxHeight: "1149px",
            }}
            rowHeight={70}
            paginationPageSize={pageSize}
            onGridReady={onGridReady}
            columnData={columnData}
            assetRowData={assetRowData}
          />
        </div>
      </ContentContainer>
    </div>
  );
};

export default Assets;

/*  const handleUpdateAssets = async () => {
    try {
      // setIsTableEdited(false);
      const requestData = { assets: [...clickedRows] };
      const hasAtleastOneItemNotExcluded = assetRowData.some(
        (rowData) => rowData.isExcluded === false
      );

      if (!hasAtleastOneItemNotExcluded) {
        setAssetErrorMessage("At least 1 asset must be available");
        return;
      }

      if (hasAtleastOneItemNotExcluded) {
        setIsTableEdited(false);
        setAssetErrorMessage(null);
        await updateAssets(requestData);
        setClickedRows([]);
      }
    } catch (error) {
      console.error("can't update the assets: ", error);
    }
  };
  */

/* function handleCheckBox(e: ChangeEvent<HTMLInputElement>, params: any) {
    params.setDataValue("isExcluded", e.target.checked);
    params.setSelected(e.target.checked);

    if (!isTableEdited) {
      setIsTableEdited(true);
    }

    setClickedRows((prev: IClickedAssetData[]): IClickedAssetData[] => {
      const { id: paramsId, isExcluded: paramsExcluded } =
        params.data as IClickedAssetData;

      const isExistingItem = prev.findIndex(
        (data) => data.id === params.data.id
      );

      if (isExistingItem !== -1) {
        const updatedArray = prev.map((item): IClickedAssetData => {
          if (item.id === paramsId) {
            return { ...item, id: paramsId, isExcluded: paramsExcluded };
          } else {
            return item;
          }
        });

        return updatedArray;
      } else {
        return [...prev, { id: paramsId, isExcluded: paramsExcluded }];
      }
    });
  }
  */
