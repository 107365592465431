export const isDarkColor = (color: string): boolean => {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Luminance formula
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance < 128; // Threshold for determining darkness
};
