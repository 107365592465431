import MainLayout from "../../layouts/main";

import Users from "../../containers/profile/users";

export default function ProfileUsers() {
  return (
    <MainLayout showSidebarLogo showSidebar>
      <Users />
    </MainLayout>
  );
}
